<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>

<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <vuexy-logo />
        <h2 class="brand-text text-primary ml-1 mt-25">
          Purple MDM
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Reset password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Reset Password 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            Enter a new password below to recover your account.
          </b-card-text>
          <b-card-text class="mb-2">
            Note: Reset tokens are only valid for 24 hours. Please
            <RouterLink to="/auth/forgot-password/">
              Click here
            </RouterLink>
            if you need to request a new one
          </b-card-text>

          <!-- form -->
          <validation-observer ref="resetValidation">
            <b-form
              class="auth-reset-form mt-2"
              @submit.prevent
            >
              <!-- new password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="new-password">New Password</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="new-password"
                      v-model="newPassword"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="new-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- new password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="confirm-password">Confirm Password</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="confirm-password"
                      v-model="confirmPassword"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="confirm-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Show reset errors -->
              <b-alert v-model="showResetErrors" variant="danger" dismissible>
                <div class="alert-body">
                  <span>{{ resetError }}</span>
                </div>
              </b-alert>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                Reset Password
              </b-button>
            </b-form>
          </validation-observer>

        </b-col>
      </b-col>
      <!-- /Reset password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardText, BCardTitle, BImg, BForm, BButton, BAlert,
} from 'bootstrap-vue'
import auth from '@/auth/auth'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BAlert,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    VuexyLogo,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      newPassword: '',
      confirmPassword: '',
      userEmail: '',
      token: this.$route.params.token,
      resetError: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    }
  },
  computed: {
    showResetErrors() {
      return this.resetError !== ''
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    logoUrl() {
      return require('@/assets/images/logo/logo.svg')
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      this.$refs.resetValidation.validate().then(success => {
        if (success) {
          auth.resetPassword({
            token: this.token,
            new_password: this.newPassword,
            new_password_confirm: this.confirmPassword,
          })
            .then(async response => {

              if (response.status === 200) {
                this.$router.push({name: 'auth-login'})
                  .then(() => {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: `Your password has been reset. Please now login`,
                        icon: 'AlertIcon',
                        variant: 'success',
                        text: response.data.message,
                      },
                    })
                  })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `There was a problem resetting your password`,
                    icon: 'AlertIcon',
                    variant: 'danger',
                    text: response.data.message,
                  },
                })
              }

            }, error => {
              if (error.response) {
                this.resetError = error.response.data.error
              } else {
                this.resetError = error.message
              }
            })
        }
      })
    },
    forgotPassword() {
      this.$router.push({ name: 'auth-forgot-password' })
    },
  },
}
</script>
